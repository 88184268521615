import React, { useState, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import fetch from 'unfetch';

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`Env var GATSBY_SITE_RECAPTCHA_KEY is undefined! `);
}

const ContactForm = () => {
  const initialState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    message: '',
  };
  const [state, setState] = useState(initialState);
  const recaptchaRef = createRef();

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    }).then(res => {
      if (res.ok) {
        resetForm('Sucessfully Sent!');

        return res;
      } else {
        const error = new Error(res.statusText);
        error.response = res;
        resetForm("Sorry, couldn't send.");

        return Promise.reject(error);
      }
    });
  };

  const resetForm = feedbackMessage => {
    setState({
      ...initialState,
      feedbackMessage,
    });
  };

  return (
    <>
      <h4 className="title is-4">Get In Touch</h4>
      <form
        data-netlify="true"
        data-netlify-recaptcha="true"
        method="POST"
        name="mlw-contact"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input name="true" type="hidden" />
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control is-expanded">
                <input
                  className="input"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="First Name"
                  type="text"
                  value={state.firstName}
                />
              </div>
            </div>
            <div className="field">
              <div className="control is-expanded">
                <input
                  className="input"
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                  type="text"
                  value={state.lastName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control is-expanded">
                <input
                  className="input"
                  name="phoneNumber"
                  onChange={handleChange}
                  placeholder="Phone Number"
                  type="text"
                  value={state.phoneNumber}
                />
              </div>
            </div>
            <div className="field">
              <div className="control is-expanded">
                <input
                  className="input"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                  type="email"
                  value={state.email}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              name="message"
              onChange={handleChange}
              placeholder="Message"
              value={state.message}
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary">Submit</button>
          </div>
        </div>
        <div className="field">
          <div className="control">{state.feedbackMessage}</div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};
