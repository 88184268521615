import React from 'react';

import Layout from '../global/Layout';
import Map from './Map';
import FormHolder from './FormHolder';
import Info from './Info';
import ContactForm from './ContactForm.js';

const Entry = () => {
  return (
    <Layout>
      <Map />
      <FormHolder>
        <ContactForm />
        <Info />
      </FormHolder>
    </Layout>
  );
};

export default Entry;
