import { css } from '@emotion/core';

export const textWrapper = css`
  margin-bottom: 0.75rem;
`;

export const table = css`
  & td {
    padding-right: 1rem
  }
`
