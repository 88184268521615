import React from 'react';

const Map = () => {
  return (
    <iframe
      width="100%"
      height="450"
      frameBorder="0"
      style={{ border: '0' }}
      src="https://www.google.com/maps/embed/v1/place?zoom=17&q=place_id:ChIJZ2V9n0Rv24ARGdGBWTpEyT4&key=AIzaSyC_f7k2Z7qGViehAhXp2NJsprSFkXVIUoI"
      allowFullScreen
    />
  );
};

export default Map;
