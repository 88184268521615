import React from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../global/SectionContainer';

const propTypes = {
  children: PropTypes.node,
};

const FormHolder = ({ children: [form, info] }) => (
  <SectionContainer>
    <div className="columns">
      <div className="column" />
      <div className="column is-7">{form}</div>
      <div className="column is-3">{info}</div>
      <div className="column" />
    </div>
  </SectionContainer>
);

FormHolder.propTypes = propTypes;

export default FormHolder;
