import React from 'react';

import * as styles from './Info.style';

const Info = () => (
  <>
    <h4 className="title is-4">More Info</h4>
    <div css={styles.textWrapper}>
      <p>Feel free to contact us with any questions you may have.</p>
    </div>
    <div css={styles.textWrapper}>
      <p>Mega Liquor Warehouse</p>
      <p>347 S. State St.</p>
      <p>Hemet, CA 92543</p>
    </div>
    <div css={styles.textWrapper}>
      <p>Tel: (951) 652 – 6575</p>
      <p>Fax: (951) 652 – 6594</p>
    </div>
    <div css={styles.textWrapper}>
      <table css={styles.table}>
        <tr>
          <td>Mon:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Tue:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Wed:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Thu:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Fri:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Sat:</td>
          <td>11am - 7pm</td>
        </tr>
        <tr>
          <td>Sun:</td>
          <td>11am - 7pm</td>
        </tr>
      </table>
    </div>
  </>
);

export default Info;
